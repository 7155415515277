const authRoute = [
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/auth/pages/login.vue"),
    meta: {
      title: "Auth",
    },
    redirect: { name: "login" },
    props: true,
    // children: [
    //   {
    //     path: "login",
    //     name: "login",
    //     meta: {
    //       title: "Login",
    //     },
    //     component: () => import("@/views/auth/pages/login.vue"),
    //   },
    // ],
  },
  {
    path: "/auth/login",
    name: "login",
    meta: {
      title: "Login",
    },
    component: () => import("@/views/auth/pages/login.vue"),
  },
  {
    path: "/auth/register",
    name: "auth.register",
    component: () => import("@/views/auth/pages/registerMain.vue"),
    meta: {
      title: "Register",
    },
  },
  {
    path: "/auth/forgot-password",
    name: "auth.forgot-password",
    component: () => import("@/views/auth/pages/forgotPassword.vue"),
    meta: {
      title: "Forgot Password",
    },
  },
  {
    path: "/auth/reset-password/:code",
    name: "auth.reset-password",
    component: () => import("@/views/auth/pages/resetPassword.vue"),
    meta: {
      title: "Reset Password",
    },
  },
  {
    path: "/auth/verify/:code",
    name: "auth.verify",
    component: () => import("@/views/auth/pages/registerMain.vue"),
    meta: {
      title: "Verify",
    },
  },
  {
    path: "/workspace",
    name: "auth.workspace",
    component: () => import("@/views/auth/pages/workspace.vue"),
    redirect: "/NbWBoa/dashboard",
    meta: {
      title: "Workspace",
    },
  },
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: "/call/:userHashedId/:roomId",
    name: "call",
    meta: {
      title: "Call",
    },
    component: () =>
      import("@/views/omni-messaging/call/components/UserCallPage.vue"),
  },
  {
    path: "/hsm/approval-template/:token",
    name: "hsm.approval-template",
    meta: {
      title: "HSM Approval Template",
    },
    component: () => import("@/views/auth/components/HsmApprovalTemplate.vue"),
  },
];
export default authRoute;
