<template>
  <img ref="image" :src="src" :alt="alt" :style="style" />
</template>

<script setup>
import { ref, defineProps, onMounted } from "vue";

const props = defineProps({
  src: {
    required: true,
    type: String,
  },
  errorImg: {
    required: true,
    type: String,
  },
  alt: String,
  style: [String, Object],
});

const image = ref();

onMounted(() => {
  if (image.value) {
    image.value.onerror = function () {
      image.value.src = props.errorImg;
    };
  }
});
</script>
