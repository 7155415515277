<template>
  <div
    id="layout-wrapper"
    :class="{
      showTopbarQuickstart: quickStart && trialAlert !== 0,
      showTopbarTrialAlert: isShowTrialAlert,
    }"
  >
    <QuickStart v-if="quickStart" :items="quickStart" />
    <TrialAlert v-if="isShowTrialAlert" :remaining="trialAlert" />
    <NavBar
      @setAlertTopbar="setAlertTopbar"
      :isCollapsed="isCollapsed"
      :toggleCollapse="toggleCollapse"
    />

    <CallWidget />

    <div>
      <!-- ========== Left Sidebar Start ========== -->
      <!-- ========== App Menu ========== -->
      <div
        :class="['app-menu navbar-menu', { _collapsed: isCollapsed }]"
        :style="{ 'background-color': subSidebarColor }"
      >
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/bawaslu-white.png" alt="" height="60" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/bawaslu-white.png" alt="" height="60" />
            </span>
            <!-- <h4
          class="fw-bold d-inline-flex ms-2 text-light"
          style="transform: translateY(4px); letter-spacing: 1.5px"
          >
          Serasi
        </h4> -->
          </router-link>
          <button
            type="button"
            class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i class="ri-record-circle-line"></i>
          </button>
          <br />
          <br />
          <simple-bar
            v-if="isCollapsed"
            style="overflow: auto; height: calc(100vh - 70px); padding: 0"
          >
            <router-link
              :key="i"
              v-for="(m, i) in modules"
              :to="m.type === 'direct' ? { name: m.slug } : '#'"
              @click="onClickCollapsedSubmodule(i, x, ix)"
              class="router-hover"
              style="
                padding: 8px;
                text-align: left;
                border-radius: 8px;
                color: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
              "
              :style="{
                'background-color':
                  `${url?.[2]}` == `${m.route}`
                    ? 'rgba(255, 255, 255, 0.25)'
                    : 'transparent',
                'border-left':
                  `${url?.[2]}` == `${m.route}`
                    ? '4px solid rgba(255, 160, 86, 1)'
                    : 'none',
              }"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                "
              >
                <i :class="[m.icon, 'fs-20']"></i>
                <span style="font-size: 10px; text-align: center">{{
                  m.name
                }}</span>
              </div>
            </router-link>
          </simple-bar>
          <simple-bar
            v-else
            style="overflow: auto; height: calc(100vh - 70px); padding: 0 16px"
          >
            <Collapse v-model:activeKey="activeKey" ghost v-if="url">
              <CollapsePanel
                :key="i"
                :show-arrow="false"
                v-for="(m, i) in modules"
                style="margin-bottom: 8px"
              >
                <template #header>
                  <router-link
                    :to="m.type === 'direct' ? { name: m.slug } : '#'"
                    style="
                      padding: 12px;
                      text-align: left;
                      border-radius: 8px;
                      color: white;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                    :style="{
                      'background-color':
                        `${url?.[2]}` == `${m.route}`
                          ? 'rgba(255, 255, 255, 0.25)'
                          : 'transparent',
                      'border-left':
                        `${url?.[2]}` == `${m.route}`
                          ? '4px solid rgba(255, 160, 86, 1)'
                          : 'none',
                    }"
                  >
                    <div style="display: flex; align-items: center">
                      <i :class="[m.icon, 'fs-20 me-3']"></i>
                      {{ m.name }}
                    </div>
                    <i
                      :class="[
                        'ri-arrow-right-s-line fs-20',
                        `arrow-indicator${
                          activeKey.includes(i + '') ? '_active' : ''
                        }`,
                      ]"
                      v-if="m.type !== 'direct'"
                    ></i>
                  </router-link>
                </template>
                <div
                  style="padding: 8px 16px 0 24px"
                  v-if="m.type !== 'direct'"
                >
                  <ul class="list-unstyled">
                    <li
                      v-for="(menu, x) in m.v3_menus"
                      :key="x"
                      style="text-align: left; color: white"
                    >
                      <p
                        class="mb-2 mt-2 fw-bold"
                        style="color: rgba(255, 148, 148, 1)"
                      >
                        {{ menu.name }}
                      </p>
                      <div style="display: flex; flex-direction: column">
                        <router-link
                          v-for="(sm, ix) in menu.submenus"
                          :key="ix"
                          :data-key="`t-${m.slug}.${menu.slug}.${sm.slug}`"
                          :to="{
                            name: `${m.slug}.${menu.slug}.${sm.slug}`,
                          }"
                          @click="onClickMenu(i, x, ix)"
                          style="
                            text-align: left;
                            border-left: 2px solid rgba(255, 255, 255, 0.25);
                            padding: 12px 8px;
                            padding-left: 32px;
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                          "
                          :style="{
                            color: isActiveUrl(i, x, ix)
                              ? 'rgba(255, 206, 137, 1) !important'
                              : '#dedede',
                            'text-decoration-line': isActiveUrl(i, x, ix)
                              ? 'underline !important'
                              : 'none !important',
                          }"
                          class="sub-module-list"
                        >
                          <span>
                            {{ sm.name }}
                          </span>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </CollapsePanel>
            </Collapse>
          </simple-bar>
        </div>
      </div>
      <!-- Left Sidebar End -->
      <!-- Vertical Overlay-->
      <div class="vertical-overlay"></div>
    </div>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div :class="['main-content', { _collapsed: isCollapsed }]">
      <div
        class="page-content"
        :style="hideFooter ? 'padding-bottom: 0px' : ''"
      >
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <!-- <BaseFooter v-if="!hideFooter" /> -->
    </div>
    <RightBar />

    <div class="modal-backdrop fade show" v-if="popupKnowledge"></div>
  </div>
</template>
<script>
import router from "@/router";
import { layoutComputed } from "@/store/helpers";
import { SimpleBar } from "simplebar-vue3";
import Hashids from "hashids";
import NavBar from "@/components/nav-bar.vue";
import QuickStart from "@/components/QuickStart.vue";
import TrialAlert from "@/components/TrialAlert.vue";
import RightBar from "@/components/right-bar.vue";
import { Collapse, CollapsePanel } from "ant-design-vue";
// import Footer from "@/components/footer.vue";
import Footer from "@/components/footer.vue";
import CallWidget from "@/components/widgets/CallWidget.vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import {
  sortBy,
  filter,
  clone,
  find,
  findIndex,
  some,
  size,
  debounce,
} from "lodash";
import { mapGetters } from "vuex";
import Toast from "@/helpers/toast.helper";
// import { shallowReactive } from "vue";
/**
 * Vertical layout
 */
export default {
  components: {
    NavBar,
    RightBar,
    // BaseFooter: Footer,
    SimpleBar,
    Collapse,
    CollapsePanel,
    QuickStart,
    TrialAlert,
    CallWidget,
  },
  props: {
    isCollapsed: Boolean,
    toggleCollapse: Function,
  },
  data() {
    return {
      activeKey: [],
      activeSubmenu: [],
      size,
      debounce,
      isMenuCondensed: false,
      domain: window.location.hostname.split(".")[0],
      knowledgeBaseUrl: `${import.meta.env.VITE_URL}${
        this.$store.getters.appId.hashed
      }/knowledgebase`,
      popupKnowledge: false,
      quickStart: undefined,
      trialAlert: undefined,
      isShowTrialAlert: false,
    };
  },
  computed: {
    ...layoutComputed,
    modules() {
      let module = this.$store.getters["sidebar/modules"];
      return sortBy(module, ["order"]);
    },
    ...mapGetters({
      userRole: "accessibility/userRole",
      access: "accessibility/getAccess",
      hideFooter: "layout/hideFooter",
      loading: "loading/isLoading",
    }),
    accessibilityUrlV3() {
      return (
        import.meta.env.VITE_OMNICHANNEL_URL +
        `/api/${this.$store.getters.appId.hashed}/v3/accessibility`
      );
    },
    url() {
      return this.$route.path.split("/");
    },
    sidebarColor() {
      const domain = window.location.hostname.split(".")[0];
      if (domain == "damcorp") {
        return "#1E5A59";
      } else if (domain == "pgd-omni") {
        return "#128145";
      } else {
        return "#bc0c0c";
      }
    },
    subSidebarColor() {
      const domain = window.location.hostname.split(".")[0];
      if (domain == "damcorp") {
        return "#4b7b7a";
      } else {
        return "#b93535";
      }
    },
  },
  created() {
    // document.body.removeAttribute("data-layout", "horizontal");
    // document.body.removeAttribute("data-topbar", "dark");
    // document.body.removeAttribute("data-layout-size", "boxed");
    // this.initActiveMenu();
    this.throttle();
  },
  mounted() {
    document.body.removeAttribute("data-layout", "vertical");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
    this.initActiveMenu();
    // this.accessibilityChecker();

    const isPopupKnowledgebase =
      window.localStorage.getItem("_popup_knowledgebase") === "true";
    if (isPopupKnowledgebase && this.$store.getters.appId) {
      setTimeout(() => {
        document.getElementById("popup-knowledge-base").click();
      }, 1000);
    }
  },
  // beforeMount() {
  //   this.accessibilityChecker();
  // },
  methods: {
    onClickCollapsedSubmodule(a, b, c) {
      this.$props.toggleCollapse();
      this.activeKey = [a];
      this.activeSubmenu = [a, b, c];
    },
    isActiveUrl(p, q, r) {
      return (
        this.activeSubmenu[0] === p &&
        this.activeSubmenu[1] === q &&
        this.activeSubmenu[2] === r
      );
    },
    onClickMenu(a, b, c) {
      if (!this.$props.isCollapsed) {
        this.activeKey = [a];
        this.activeSubmenu = [a, b, c];
      }
    },
    closeSidebar() {
      this.toggleHamburgerMenu();
      console.log("congrats, your sub menu clicked :)");
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
        document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu")
          ? document.body.classList.remove("menu")
          : document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm"
            ? document.documentElement.setAttribute("data-sidebar-size", "")
            : document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg"
            ? document.documentElement.setAttribute("data-sidebar-size", "sm")
            : document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel")
          ? document.body.classList.remove("twocolumn-panel")
          : document.body.classList.add("twocolumn-panel");
      }
    },
    throttle: debounce(function () {
      this.accessibilityChecker();
    }, 500),
    initActiveMenu() {
      const pathName = window.location.pathname;
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = Array.from(ul.querySelectorAll("a.nav-link"));
        let activeItems = items.filter((x) => x.classList.contains("active"));
        this.removeActivation(activeItems);
        let matchingMenuItem = items.find((x) => {
          return x.getAttribute("href") === pathName;
        });
        if (matchingMenuItem) {
          this.activateParentDropdown(matchingMenuItem);
        } else {
          var id = pathName.replace("/", "");
          // if (id) document.body.classList.add("twocolumn-panel");
          this.activateIconSidebarActive(pathName);
        }
      }
    },

    updateMenu(val) {
      // animate hamburger icon to arrow
      console.log("updateMenu", val);
      if (
        document.querySelector(".hamburger-icon") &&
        !document.querySelector(".open")
      ) {
        document.querySelector(".hamburger-icon").classList.toggle("open");
      }
      let haveBot = cookies.get("lenna_bot_id");
      // console.log("have bot", haveBot);
      if (val.type == "direct" || (!haveBot && val.url == "studio")) {
        return this.$router.push({ name: val.slug });
      }
      document.body.classList.remove("twocolumn-panel");
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = Array.from(ul.querySelectorAll(".show"));
        items.forEach((item) => {
          item.classList.remove("show");
        });
      }
      const icons = document.getElementById("two-column-menu");
      if (icons) {
        const activeIcons = Array.from(
          icons.querySelectorAll(".nav-icon.active")
        );
        activeIcons.forEach((item) => {
          item.classList.remove("active");
        });
      }
      document.getElementById(`sidebar_${val.slug}`).classList.add("show");
      this.activateIconSidebarActive("#sidebar_" + val.slug);
    },

    removeActivation(items) {
      items.forEach((item) => {
        if (item.classList.contains("menu-link")) {
          if (!item.classList.contains("active")) {
            item.setAttribute("aria-expanded", false);
          }
          item.nextElementSibling.classList.remove("show");
        }
        if (item.classList.contains("nav-link")) {
          if (item.nextElementSibling) {
            item.nextElementSibling.classList.remove("show");
          }
          item.setAttribute("aria-expanded", false);
        }
        item.classList.remove("active");
      });
    },

    activateIconSidebarActive(id) {
      var menu = document.querySelector(
        "#two-column-menu .simplebar-content-wrapper a[href='" +
          id +
          "'].nav-icon"
      );
      if (menu !== null) {
        menu.classList.add("active");
      }
    },

    activateParentDropdown(item) {
      // navbar-nav menu add active
      item.classList.add("active");
      let parentCollapseDiv = item.closest(".collapse.menu-dropdown");
      if (parentCollapseDiv) {
        // to set aria expand true remaining
        parentCollapseDiv.classList.add("show");
        parentCollapseDiv.parentElement.children[0].classList.add("active");
        parentCollapseDiv.parentElement.children[0].setAttribute(
          "aria-expanded",
          "true"
        );
        if (
          parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
        ) {
          this.activateIconSidebarActive(
            "#" +
              parentCollapseDiv.parentElement
                .closest(".collapse.menu-dropdown")
                .getAttribute("id")
          );
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .classList.add("show");
          if (
            parentCollapseDiv.parentElement.closest(".collapse")
              .previousElementSibling
          )
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .previousElementSibling.classList.add("active");
          return false;
        }
        this.activateIconSidebarActive(
          "#" + parentCollapseDiv.getAttribute("id")
        );
        return false;
      }
      return false;
    },

    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },

    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },

    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },

    toAuth() {
      // window.location.href = import.meta.env.VITE_AUTH_URL;
      this.$router.push({ name: "auth.workspace" });
    },
    async accessibilityChecker() {
      // await this.$store.dispatch("sidebar/getMenus");
      // await this.$store.dispatch("accessibility/getRole");
      await this.$store.dispatch("accessibility/getRoleV3");
      // if (this.userRole.new_platform == false) {
      //   if (
      //     this.userRole.slug == "admin" ||
      //     this.userRole.slug == "super-admin"
      //   ) {
      //     return this.$router.push({ name: "update-access" });
      //   }
      //   return this.$router.push({ name: "contact-admin" });
      // }
      if (this.$store.getters.localStorage.decodedUserId) {
        await this.$store.dispatch("integration/getIntegrationData");
        this.fetchBotIntegration();
        const response = await this.$axios.get(this.accessibilityUrlV3);
        if (!response.data.success) {
          // console.log("not success");
          this.$router.replace({ name: "notfound" });
          // this.$router.push("/404");
          // this.$store.dispatch("sidebar/updateMenus", null);
          this.$store.dispatch("sidebar/updateModules", null);
          return Toast.error(response.data.message);
        }
        this.$store.dispatch(
          "accessibility/setAccessibility",
          response.data.data
        );
        // this.$store.dispatch("sidebar/updateMenus", this.access.menu);
        this.$store.dispatch("sidebar/updateModules", this.access.module);
        const listApps = some(this.access.apps, {
          id: this.$route.params.appId,
        });
        if (!listApps) {
          // console.log("cannot access project");
          this.$router.push({ name: "forbidden" });
          // this.$store.dispatch("sidebar/updateMenus", null);
          this.$store.dispatch("sidebar/updateModules", null);
          this.errorToast(
            "You need invited to this app before access, contact your admin!"
          );
        }
        const currentModuleUrl = this.url[2];
        const canAccessThisModuleUrl = some(this.access.module, function (o) {
          return o.url == currentModuleUrl;
        });
        if (!canAccessThisModuleUrl) {
          if (this.$router.name !== "billing") {
            // return this.$router.push({ name: "forbidden" });
            this.goToNearestModuleMenu(this.access.module);
          }
        } else {
          let currentModuleData = find(this.access.module, function (m) {
            return m.url == currentModuleUrl;
          });
          if (currentModuleData.v3_menus.length > 0) {
            const currentMenuUrl = this.url[3];
            const canAccessThisMenuUrl = some(
              currentModuleData.v3_menus,
              function (o) {
                return o.url == currentMenuUrl;
              }
            );
            if (!canAccessThisMenuUrl) {
              // return this.$router.push({ name: "forbidden" });
              this.goToNearestModuleMenu(this.access.module);
            }
          }
          // this.goToMenuOfCurrentModule(this.access.module);
        }
        await this.$store.dispatch("integration/getIntegrationData", "bot");
      } else {
        // console.log("notfound");
        // this.$router.push({ name: "404" });
        this.$router.push({ name: "login" });
      }
    },
    goToNearestModuleMenu(modules) {
      console.log("goToNearestModuleMenu");
      this.haveAccessToCurrentApp = true;
      const moduleUrl = this.url[2];
      const moduleIdx = findIndex(modules, function (module) {
        return module.url == moduleUrl;
      });
      // console.log("moduleIdx", moduleIdx);
      if (moduleIdx == -1) {
        if (size(modules[0].menus) > 0) {
          return this.moduleHaveMenus(modules, 0);
        }
        return this.goToFirstNearestModule(modules);
      }
      if (size(modules[moduleIdx].menus) > 0) {
        return this.moduleHaveMenus(modules, moduleIdx);
      }
    },
    goToFirstModule(modules) {
      // console.log("goToFirstModule");
      this.$router
        .push({
          name: modules[0].route,
        })
        .catch((err) => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
    },
    moduleHaveMenus(modules, moduleIdx) {
      console.log("moduleHaveMenus");
      const menuUrl = this.url[3];
      const menuIdx = findIndex(modules[moduleIdx].v3_menus, function (menu) {
        return menu.slug == menuUrl;
      });
      if (menuIdx !== -1) {
        this.goToCurrentMenuOfCurrentModule(modules, moduleIdx, menuIdx);
      } else {
        this.goToFirstMenuOfCurrentModule(modules, moduleIdx);
      }
    },
    goToMenuOfCurrentModule(modules) {
      console.log("goToMenuOfCurrentModule", modules);
      const menuUrl = this.url[3];
      const moduleUrl = this.url[2];
      console.log("this.url", this.url);
      this.haveAccessToCurrentApp = true;
      const moduleIdx = findIndex(modules, function (module) {
        return module.url == moduleUrl;
      });
      if (size(modules[moduleIdx].v3_menus) > 0) {
        if (menuUrl !== undefined) {
          const menuIdx = findIndex(
            modules[moduleIdx].v3_menus,
            function (menu) {
              return menu.url == menuUrl;
            }
          );
          console.log("menuIdx", menuIdx);
          if (menuIdx !== -1) {
            return this.goToCurrentMenuOfCurrentModule(
              modules,
              moduleIdx,
              menuIdx
            );
          }
        }
      }
      this.goToFirstMenuOfCurrentModule(modules, moduleIdx);
    },
    // goToSubmenuOfCurrent
    goToCurrentMenuOfCurrentModule(modules, moduleIdx, menuIdx) {
      console.log("goToCurrentMenuOfCurrentModule modul", moduleIdx);
      console.log("goToCurrentMenuOfCurrentModule menu", menuIdx);
      const submenuUrl = this.url[4];
      if (size(modules[moduleIdx].v3_menus[menuIdx].submenus) > 0) {
        const submenuIdx = findIndex(
          modules[moduleIdx].v3_menus[menuIdx].submenus,
          function (s) {
            return s.url == submenuUrl;
          }
        );
        if (submenuIdx !== -1) {
          return this.$router
            .push({
              name: `${modules[moduleIdx].slug}.${modules[moduleIdx].v3_menus[menuIdx].slug}.${modules[moduleIdx].v3_menus[menuIdx].submenus[submenuIdx].slug}`,
            })
            .catch((err) => {
              if (err.name != "NavigationDuplicated") {
                throw err;
              }
            });
        }
      }
      this.$router
        .push({
          name: `${modules[moduleIdx].v3_menus[menuIdx].slug}`,
        })
        .catch((err) => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
    },
    goToFirstMenuOfCurrentModule(modules, moduleIdx) {
      console.log("goToFirstMenuOfCurrentModuleIdx", moduleIdx);
      // console.log("goToFirstMenuOfCurrentModule modules", modules);
      // if (size(modules[moduleIdx].v3_menus) == 0) {
      //   this.$router
      //     .push({
      //       // name: modules[moduleIdx].v3_menus[0].slug
      //       name: this.url[3] ? `${this.url[2]}.${this.url[3]}` : this.url[2],
      //     })
      //     .catch((err) => {
      //       if (err.name != "NavigationDuplicated") {
      //         throw err;
      //       }
      //     });
      // } else if (size(modules[moduleIdx].v3_menus) > 0) {
      //   this.$router
      //     .push({
      //       name: this.url[3] ? `${this.url[2]}.${this.url[3]}` : this.url[2],
      //     })
      //     .catch((err) => {
      //       if (err.name != "NavigationDuplicated") {
      //         throw err;
      //       }
      //     });
      // } else {
      //   this.goToFirstModule(modules);
      // }
    },
    goToFirstNearestModule(modules) {
      if (this.$route.name !== "billing") {
        console.log("goToFirstNearestModule", modules);
        this.$router
          .push({
            name: `${modules[0].slug}`,
          })
          .catch((err) => {
            if (err.name != "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },
    validateActiveBot(channels, activeBotId) {
      let result = false;
      for (const channel of channels) {
        for (const integration of channel.integration) {
          if (integration.bot_id == activeBotId) {
            result = true;
          }
        }
      }

      return result;
    },
    async fetchBotIntegration() {
      const integrations = this.$store.getters["integration/integrations"];
      const botIntegration = filter(integrations, function (o) {
        return o.name == "bot";
      });
      if (size(botIntegration[0].integration) > 0) {
        const botId = clone(
          botIntegration[0].integration[0].integration_data.botId
        );
        let hashids = new Hashids("", 6);
        var hashedBotId = hashids.encode(botId);
        this.$store.dispatch("bot/getBotList");

        if (!cookies.get("lenna_bot_id")) {
          this.$store.dispatch("bot/setBotId", hashedBotId);
        } else {
          const activeBotId = hashids.decode(cookies.get("lenna_bot_id"));
          if (!this.validateActiveBot(integrations, activeBotId[0])) {
            this.$store.dispatch("bot/setBotId", hashedBotId);
          }
        }
      } else {
        cookies.remove("lenna_bot_id");
      }
      // const fullBotStudioUrl = `${this.botStudioUrl}/${hashedBotId}/stories`;
      // this.$store.dispatch("sidebar/alterUrlModule", {
      //   slug: "conversation-studio",
      //   url: fullBotStudioUrl
      // });
    },
    showPopupKnowledgebase() {
      this.popupKnowledge = true;
      setTimeout(() => {
        const $popover = document.querySelector(".popover.show");

        if ($popover) {
          $popover.classList.add("popover-knowledgebase");
        }
      }, 100);
    },
    hidePopupKnowledgebase(redirect) {
      this.popupKnowledge = false;
      window.localStorage.removeItem("_popup_knowledgebase");

      if (redirect) {
        window.open("https://faq.lenna.ai/lenna", "_blank");
      }
    },
    setAlertTopbar({ quick_start, trial_alert }) {
      this.quickStart = quick_start;
      this.trialAlert = trial_alert;
      this.isShowTrialAlert = !quick_start || trial_alert === 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-menu .navbar-nav .nav-sm .nav-sm .nav-link:before {
  display: none;
}
.menu-name {
  color: #fff;
  display: block;
  font-size: 10px;
}

.menu-article {
  position: absolute !important;
  bottom: 15px;
  left: 15px;
}

.menu-doc a {
  background-color: #e5f2fc;
  width: 35px !important;
  height: 35px !important;
}

.app-menu .navbar-menu {
  margin-top: 0.3rem;
  margin-left: 0.1rem;
}
</style>

<style lang="scss">
.popover.popover-knowledgebase {
  max-width: 330px !important;
  border-radius: 16px !important;

  .popover-body {
    padding: 0 !important;
  }

  a {
    font-size: 0.7125rem !important;
  }
}

div#layout-wrapper.showTopbarQuickstart {
  header#page-topbar,
  .app-menu.navbar-menu {
    top: 55px !important;
  }

  div.main-content {
    margin-top: 55px !important;
  }
}

div#layout-wrapper.showTopbarTrialAlert {
  header#page-topbar,
  .app-menu.navbar-menu {
    top: 43px !important;
  }

  div.main-content {
    margin-top: 43px !important;
  }
}

.sub-module-list {
  transition: all 0.3s ease-in-out;
  position: relative;
  &:hover {
    // background-color: rgba(255, 151, 71, 0.42);
    // border-top-right-radius: 8px;
    // border-bottom-right-radius: 8px;
    &::before {
      height: 40px;
      width: 200px;
      background-color: rgba(255, 255, 255, 0.25);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    transition: all 0.15s ease-in-out;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 15px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.25);
  }
}

.arrow-indicator {
  transition: all 0.15s ease-in-out;
  transform: trotate(0deg);
}
.arrow-indicator_active {
  transition: all 0.15s ease-in-out;
  transform: rotate(90deg) !important;
}
.router-hover {
  margin-bottom: 4px;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
    border-left: 4px solid rgba(255, 160, 86, 1) !important;
  }
}

.router-submodule {
  position: absolute;
  top: 0;
  background-color: #b93535;
}
</style>
