<script>
import { layoutComputed } from "@/store/helpers";

import Vertical from "./vertical.vue";
import Horizontal from "./horizontal.vue";
import TwoColumns from "./twocolumn.vue";

export default {
  components: {
    Vertical,
    Horizontal,
    TwoColumns,
  },
  data() {
    return {
      blacklistInternalPageOn: ["auth.workspace", "omni.conversation.chat"],
      isCollapsed: false,
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      localStorage.setItem("is_collapsed", this.isCollapsed);
    },
  },
  computed: {
    ...layoutComputed,
    isLoading() {
      if (this.$route.path.split("/")[2] != "dashboard") {
        return this.$store.getters["loading/isLoading"];
      } else {
        return false;
      }
    },
    assignedChannel() {
      return this.$store.getters["agent/assignedChannel"];
    },

    checkBlacklistInternalChat() {
      return (
        this.blacklistInternalPageOn.findIndex(
          (r) => r === this.$route.name
        ) === -1
      );
    },
  },
  beforeCreate() {
    this.$store.dispatch("agent/assignedChannel");
    // this.$store.dispatch("agent/updateOnline", {
    //   state: "online",
    //   // online: true,
    // });
  },
  created() {
    window.onresize = () => {
      this.$store.commit("UPDATE_WIDTH");
    };
    const isCollapsed_ = localStorage.getItem("is_collapsed");
    this.isCollapsed = isCollapsed_ === "true";
  },
};
</script>

<template>
  <div>
    <!-- <Vertical :layout="'vertical'">
      <slot />
    </Vertical> -->
    <!-- <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal> -->

    <TwoColumns
      v-if="layoutType === 'twocolumn'"
      :layout="layoutType"
      :isCollapsed="isCollapsed"
      :toggleCollapse="toggleCollapse"
    >
      <slot />
    </TwoColumns>
  </div>
</template>
