<template>
    <div class="auth-page-wrapper auth-bg-cover pt-5 min-vh-100">
        <!-- auth page bg -->
        <div class="bg-overlay"></div>

        <!-- auth page content -->
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img src="@/assets/images/logo-light.png" alt="" height="20" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">
                            <div class="card-body p-4 text-center">
                                <div v-if="!isLoading">
                                    <div v-if="status">
                                        <div class="avatar-lg mx-auto mt-2">
                                            <div class="avatar-title bg-light text-success display-3 rounded-circle">
                                                <i class="ri-checkbox-circle-fill"></i>
                                            </div>
                                        </div>
                                        <div class="mt-4 pt-2">
                                            <h4>Well done !</h4>
                                            <p class="text-muted mx-4">
                                                {{ message }}
                                            </p>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="avatar-lg mx-auto mt-2">
                                            <div class="avatar-title bg-light text-danger display-3 rounded-circle">
                                                <i class="ri-close-circle-fill"></i>
                                            </div>
                                        </div>
                                        <div class="mt-4 pt-2">
                                            <h4>Failed !</h4>
                                            <p class="text-muted mx-4">
                                                {{ message }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center d-flex justify-content-center" v-else>
                                    <b-spinner label="Spinning" variant="primary"></b-spinner>
                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->
                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->
    </div>
    <!-- end auth-page-wrapper -->
</template>

<script>
import AuthService from '@/services/AuthService';
export default {
    name: 'verify',
    data() {
        return {
            isLoading: true,
            status: false,
            message: ''
        };
    },
    async created() {
        const username = this.$route.query.usdesk;
        const phone = this.$route.query.phone;
        this.$store.dispatch('loading/updateIsLoading', false);
        const session = localStorage.getItem('user');
        if (!session) {
            try {
                const { message } = await AuthService.sso({
                    username: username
                });
                this.isLoading = false;
                console.log(message);
                if (message != 'Success login') {
                    this.status = false;
                    this.message = 'User Not Found';
                    return;
                }
            } catch (e) {
                console.log('err', e);
            }
        }
        this.status = true;
        this.message = "Success Login, You'll be redirected in minutes";
        let phoneStr = '';
        if (phone) {
            phoneStr = '?phone=' + phone;
        } else {
            await this.pbxLogin();
        }
        window.location = this.urlTemp + `/${this.$route.params.appId}/ticketing/manage/create360${phoneStr}`;
        // this.$router.push({ name: 'ticketing.tickets.create-ticket360', query: { phone: phone } });
    },
    computed: {
        urlTemp() {
            let url = import.meta.env.VITE_APP_URL;
            if (window.location.hostname.split('.')[0] == 'damcorp') {
                url = import.meta.env.VITE_DAMCORP_URL;
            } else if (window.location.hostname.split('.')[0] == 'bawaslu') {
                url = import.meta.env.VITE_BAWASLU_URL;
            } else if (window.location.hostname.split('.')[0] == 'pgd-omni') {
                url = import.meta.env.VITE_PEGADAIAN_URL;
            } else {
                url = import.meta.env.VITE_APP_URL;
            }
            return url;
        }
    },
    methods: {
        async pbxLogin() {
            try {
                await fetch('http://localhost:60024/pbxlogin');
                await this.wait(8000);
                await fetch('http://localhost:60024/pbxin');
            } catch (e) {
                console.log('err', e);
            }

            return true;
        },
        wait(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
    }
};
</script>
