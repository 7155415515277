<style lang="scss" scoped>
// make components floating
.call-widget {
    position: fixed;
    right: 10%;
    top: 10%;
    z-index: 9999;
    box-shadow: 0px 6px 23px -3px rgba(0, 0, 0, 0.65);
    -webkit-box-shadow: 0px 6px 23px -3px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 0px 6px 23px -3px rgba(0, 0, 0, 0.65);
    border-radius: 10%;
    min-width: 200px;
}

// add animation to floating components
.call-widget {
    animation: floating 2s ease-in-out infinite;
}

@keyframes floating {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
</style>
<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';
import { httpClientOmni } from '@/libraries';

const route = useRoute();
const store = useStore();

const phone = route.query.phone;
const name = ref('unknown');
const state = ref(true);
const currentCallId = ref(null);

const acceptCall = async () => {
    console.log('Accepting call');
    state.value = false;
    // fetch api to accept call
    try {
        const response = await fetch('http://localhost:60024/answer', {
            method: 'GET'
        });

        const { data } = await response.json();
        console.log(data);
        if (data.success) {
            console.log(data);
        }
    } catch (error) {
        console.log(error);
    }

    httpClientOmni
        .post('call/pickup', {
            phone: phone
        })
        .then((response) => {
            console.log(response);
            currentCallId.value = response.data.data;
        });
};

const rejectCall = async () => {
    console.log('Rejecting call', currentCallId.value);
    store.commit('app/SET_CALL_STATE', false);
    state.value = true;
    try {
        const response = await fetch('http://localhost:60024/drop', {
            method: 'GET'
        });
    } catch (error) {
        console.log(error);
    }

    httpClientOmni
        .post('call/drop', {
            call_id: currentCallId.value
        })
        .then((response) => {
            console.log(response); 
        });
};

onMounted(async () => {
    if (phone) {
        const { data: user } = await store.dispatch('customers/getCustomerByPhone', phone);
        if (user) {
            name.value = user.name;
        }
    }
});
</script>
<template>
    <Transition name="bounce">
        <div class="call-widget p-3 bg-white" v-if="store.getters['app/callState']">
            <div class="d-flex flex-column align-items-center">
                <div class="mb-2">
                    <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text fs-24">Unknown</span>
                    <span class="d-none d-xl-block ms-1 fs-12 user-name-sub-text fs-20 text-muted">{{ phone }}</span>
                </div>
                <div class="align-items-center">
                    <TransitionGroup name="fade">
                        <base-button variant="success" class="rounded" @click="acceptCall" :class="{ 'd-none': !state }"
                            >Accept</base-button
                        >
                        <base-button variant="danger" class="rounded" @click="rejectCall" :class="{ 'd-none': state }">End</base-button>
                    </TransitionGroup>
                </div>
            </div>
        </div>
    </Transition>
</template>
